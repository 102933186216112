<!--
 * @Author: gjm
 * @Date: 2021-04-15 13:18:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-02 17:36:40
 * @Description: 在线咨询-创建问题
-->
<style lang="less" scoped>
.reply-question_footer {
  position: absolute;
  background: rgba(207, 214, 228, 0.25);
  border-radius: 1px 1px 1px 1px;
  width: 310px;
  font-size: 12px;
  font-weight: normal;
  color: #46546c;
  padding: 5px 0;
}
/deep/ .ant-form-item {
  margin-bottom: 12px !important;
}
.source-target {
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <a-modal
    :visible="questionreply"
    class="reply-question"
    :width="576"
    title="回应"
    @cancel="handleCancel"
    okText="提交"
    @ok="handleOk"
  >
    <template slot="footer">
      <div class="reply-question_footer">注：针对本次问题，您仅有一次回答机会，请谨慎回答！</div>
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk"> 提交 </a-button>
    </template>
    <div class="question-box">
      <a-form-model ref="question" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="问题">
          <div class="text-wrap pt-1" style="line-height: 23px">{{ question.questionContext }}</div>
        </a-form-model-item>
        <a-form-model-item label="语言对">
          <a-row>
            <a-col :span="11">
              <a-select
                allowClear
                show-search
                :filter-option="langFilterOption"
                v-model="form.sourceCode"
                :placeholder="$t('orderInfo.source')"
                disabled
              >
                <a-select-option v-for="(item, index) in sourceCodeList" :key="index" :value="item.code">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="2">
              <div class="source-target">></div>
            </a-col>
            <a-col :span="11">
              <a-select
                allowClear
                show-search
                :filter-option="langFilterOption"
                v-model="form.targetCodeList"
                :placeholder="$t('orderInfo.target')"
                mode="multiple"
                disabled
              >
                <a-select-option v-for="(item, index) in targetCodeList" :key="index" :value="item.code">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="解决办法" required prop="replyContext">
          <a-textarea
            v-model="form.replyContext"
            placeholder="请输入您的解决办法......"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :maxlength="500"
          />
        </a-form-model-item>
        <a-form-model-item label="附件">
          <NeoUploadButton
            title="上传附件"
            v-model="form.attachmentFile"
            :showNote="form.attachmentFile.length > 0 ? false : true"
            :showFileList="true"
            :showIcon="true"
            accept=".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .zip"
            :maxSize="10"
            :maxLength="1"
            note="支持doc、docx、xlsx、xls、pdf、png、jpg、zip格式，且大小不超过10M"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import debounce from 'lodash/debounce'

const { validate, acceptMap } = window.$g

export default {
  name: 'QuestionReplyModal',
  components: {
    NeoUploadButton,
  },
  props: {
    questionreply: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      acceptMap,
      uploading: false,
      form: {
        replyContext: undefined,
        attachmentFile: [],
      },
      rules: {
        replyContext: [validate.required],
      },
      sourceCodeList: [],
      targetCodeList: [],
      loading: false,
    }
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
    }),
  },
  watch: {
    questionreply(v) {
      if (v) {
        console.log('this.question :>> ', this.question)
        this.form.sourceCode = this.question.sourceCode ? this.question.sourceCode : undefined
        this.form.targetCodeList = this.question.targetCode ? this.question.targetCode?.split(',') : undefined
        this.sourceCodeList = this.langList.filter((_) => _.code == this.question.sourceCode)
        this.targetCodeList = this.langList.filter((_) => this.question.targetCode?.split(',').includes(_.code))
      } else {
        this.form.replyContext = undefined
        this.form.attachmentFile = []
        this.$refs.question.clearValidate()
      }
    },
  },
  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleCancel() {
      this.$emit('closeQuestionReply', false)
    },
    handleOk: debounce(function () {
      this.loading = true
      try {
        const params = {
          orderId: this.question.orderId,
          orderQuestionId: this.question.id,
          replyContext: this.form.replyContext,
          sourceCode: this.form.sourceCode,
          targetCode: this.form.targetCodeList?.join(','),
          attachmentFile: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].url : null,
          attachmentName: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].name : null,
          answerType: this.$g.isCust ? 1 : 2,
        }
        this.$refs.question.validate(async (valid) => {
          if (valid) {
            await this.$http('createQuestionReply', params)
              .then(() => {
                this.$emit('confirm')
                this.$emit('closeQuestionReply', false)
              })
              .catch((err) => {
                this.$httpNotify(err)
              })
          }
        })
        this.loading = false
      } catch (error) {
        this.$httpNotify(error)
      }
    }, 1000),
  },
}
</script>
